@media (max-width: 480px) {
  .main-title {
    font-size: 16px;
  }
  .main-para {
    font-size: 12px;
  }
  .title {
    font-size: 24px;
  }
  .description {
    font-size: 12px;
  }
  .header-title {
    font-size: 12px;
  }
  .header-description {
    font-size: 14px;
  }
  .header-admin {
    font-size: 9px;
  }
  .main-page-description {
    font-size: 14px;
  }
  .footer-p {
    font-size: 8px;
  }
  .footer-a {
    font-size: 14px;
  }
  #reg_btn,
  #log_btn {
    font-size: 2vw;
    width: 25vw;
  }
  /* form {
    
    padding: 2.5vh;
  }
  input {
    width: 60vw;
    padding: 0.7vh;
    border-radius: 2vw;
    font-size: 0.9rem;
  } */
  label {
    font-size: 2.5vw;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .main-title {
    font-size: 19px;
  }
  .main-para {
    font-size: 14px;
  }
  .title {
    font-size: 26px;
  }
  .description {
    font-size: 14px;
  }
  .header-title {
    font-size: 14px;
  }
  .header-description {
    font-size: 14px;
  }
  .header-admin {
    font-size: 9px;
  }
  .main-page-description {
    font-size: 15px;
  }
  .footer-p {
    font-size: 12px;
  }
  .footer-a {
    font-size: 18px;
  }
  #reg_btn,
  #log_btn {
    font-size: 2vw;
    width: 25vw;
  }
  /* form {
    width: 80vw;
    padding: 2.5vh;
  }
  input {
    width: 50vw;
    padding: 0.7vh;
    border-radius: 1vw;
    font-size: 0.9rem;
  } */
  label {
    font-size: 2.5vw;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .main-title {
    font-size: 22px;
  }
  .main-para {
    font-size: 20px;
  }
  .title {
    font-size: 30px;
  }
  .description {
    font-size: 14px;
  }
  .header-title {
    font-size: 18px;
  }
  .header-description {
    font-size: 16px;
  }
  .header-admin {
    font-size: 10px;
  }
  .main-page-description {
    font-size: 16px;
  }
  .footer-p {
    font-size: 12px;
  }
  .footer-a {
    font-size: 18px;
  }
  #reg_btn,
  #log_btn {
    font-size: 1.2vw;
    width: 15vw;
  }
  /* form {
    width: 50vw;
    padding: 1.5vh;
  }
  input {
    width: 30vw;
    padding: 0.7vh;
    border-radius: 0.5vw;
    font-size: 0.9rem;
  } */
  label {
    font-size: 2vw;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .main-title {
    font-size: 25px;
  }
  .main-para {
    font-size: 25px;
  }
  .title {
    font-size: 35px;
  }
  .description {
    font-size: 16px;
  }
  .header-title {
    font-size: 20px;
  }
  .header-description {
    font-size: 18px;
  }
  .header-admin {
    font-size: 12px;
  }
  .main-page-description {
    font-size: 18px;
  }
  .footer-p {
    font-size: 12px;
  }
  .footer-a {
    font-size: 18px;
  }
  #reg_btn,
  #log_btn {
    font-size: 1.2vw;
    width: 15vw;
  }
  /* form {
    width: 50vw;
    padding: 1.5vh;
  }
  input {
    width: 30vw;
    padding: 0.7vh;
    border-radius: 0.5vw;
    font-size: 0.9rem;
  } */
  label {
    font-size: 1.5vw;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .main-title {
    font-size: 45px;
  }
  .main-para {
    font-size: 25px;
  }
  .title {
    font-size: 40px;
  }
  .description {
    font-size: 20px;
  }
  .header-title {
    font-size: 22px;
  }
  .header-description {
    font-size: 18px;
  }
  .header-admin {
    font-size: 12px;
  }
  .main-page-description {
    font-size: 18px;
  }
  .footer-p {
    font-size: 12px;
  }
  .footer-a {
    font-size: 18px;
  }
  #reg_btn,
  #log_btn {
    font-size: 1.2vw;
    width: 15vw;
  }
  /* form {
    padding: 2.5vh 5vw;
  }
  input {
    width: 30vw;
    padding: 0.7vh;
    border-radius: 0.5vw;
    font-size: 0.9rem;
  } */
  label {
    font-size: 1.2vw;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.landing-page {
  justify-content: center;
}

.auth-page {
  align-items: center;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: "rgba(0, 0, 0, 0.95)";
  background-image: url(./assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body {
  margin: 0 auto;
}

.primary-button {
  margin-top: 1vh;
  padding: 0.6rem;
  width: 10rem;
  background: #222;
  border: none;
  color: #fff;
  font-size: 1.2vw;
  transition: all 0.5s;
  cursor: pointer;
  text-transform: capitalize;
}

/***    Landing Page     ***/
.main-title,
.main-para,
.title,
.description {
  font-family: "Montserrat", sans-serif;
  color: #f1f1f1;
}

.main-title {
  text-transform: uppercase;
  font-weight: "900";
}

#reg_btn span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#reg_btn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#reg_btn:hover span {
  padding-right: 25px;
}

#reg_btn:hover span:after {
  opacity: 1;
  right: 0;
}

#reg_btn,
#log_btn {
  border: 2px solid #b3b003;
  border-radius: 7vw;
  margin-left: 5px;
  margin-right: 5px;
}

/***    Login Page     ***/
h2 {
  font-weight: 300;
}

form {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  padding: 20px;
}

form label {
  float: left;
  margin: 0;
  padding: 0;
  color: white;
}

input {
  width: 100%;
  padding: 0.7vh;
  border-radius: 0.5vw;
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
button,
label,
th,
td,
tr,
text {
  font-family: "Montserrat", sans-serif;
}
text {
  cursor: pointer;
}

.right-label {
  float: right;
  cursor: pointer;
}

#sub_btn {
  width: 100%;
  padding: 0.3rem;
  border: none;
  background: rgb(65, 64, 64);
  color: #fff;
  border-radius: 0.5vw;
}

#sub_btn:hover {
  background: #333;
  transition: all 0.5s;
}

footer p {
  margin: 0;
  font-size: 0.9rem;
}

/***    Register Page     ***/
#checkbox {
  width: 1rem;
}

form span {
  font-size: 0.8vw;
}

#reset_pass_lbl {
  float: left;
}

/***    Home Page     ***/
.home-page-title {
  color: #222;
}

/***   Main Page  ***/
.header-bar {
  background-color: #b3b003;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.header-icon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.header-title,
.header-admin {
  padding: 0;
  margin: 0;
  color: white;
}
.header-title {
  font-weight: 500;
  cursor: pointer;
}
.expanded-view {
  flex: 1;
}
.header-description {
  font-weight: 400;
}

.tag {
  padding: 7px;
  border-radius: 30px;
  border: #222 1px solid;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  margin-right: 7px;
  font-size: small;
}

.tagInputField {
  border: #ccc 1px solid;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 40px;
}

.material-icons.red {
  color: #5a0101;
}

#imageViewer {
  height: 300px;
  width: 400px;
  position: relative;
}

#dp {
  height: 300px;
  width: 400px;
  border-radius: 10px;
  border: 3px solid #b3b003;
  opacity: 1;
}

#overlay {
  opacity: 0;
  display: flex;
  position: absolute;
  height: 300px;
  width: 400px;
  top: 0;
  left: 0;
  background-color: #22222222;
  transition: all ease 0.5s;
  align-items: center;
  justify-content: center;
}

#overlay-text {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
}

#imageViewer:hover #dp {
  opacity: 0.3;
}

#imageViewer:hover #overlay {
  opacity: 1;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b3b003;
  border-bottom-left-radius: 3px;
  border-end-end-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b003;
}
